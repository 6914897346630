<template>
  <form ref="form" class="itinerary step_1_hotel_form" :data-uuid="itinerary.id" :data-uuid-raw="itinerary.attributes.uuid" @submit="submit">
    <input type="hidden" name="uuid" :value="itinerary.attributes.uuid" />

    <div class="hotel_result" :class="{ 'overlay': locked }">
      <div class="image_wrap" v-if="itinerary.attributes.pictures[0]">
        <a class="hotel_gallery thumb_a" :href="itinerary.attributes.pictures[0].url" data-fancybox="gallery">
          <div class="image">
            <div class="resize-and-crop" style="width: 250px; height: 200px; text-align: center;">
              <img v-if="this.imagePath" :src="this.imagePath | cloudinary({ h: 200, w: 250 })" width="250" height="200" class="big_thumb" style="left: -53px; top: 0px;">
              <img v-else :src="itinerary.attributes.pictures[0].url | cloudinary({ h: 200, w: 250 })" width="250" height="200" class="big_thumb" style="left: -53px; top: 0px;">
            </div>
          </div>
        </a>

        <div class="other_images">
          <div v-for="pic in itinerary.attributes.pictures" :key="pic.url" class="small_image">
            <a class="hotel_gallery" :href="pic.url" data-fancybox="gallery">
              <div class="resize-and-crop" style="width: 60px; height: 40px;">
                <img :alt="pic.description" :src="pic.url | cloudinary({ h: 40, w: 60 })" width="60" height="40" class="small_thumb_img" style="left: 0px; top: -25px;" @mouseenter="setImage(pic.url)" @mouseleave="setImage(null)">
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="information">
        <div class="name_group">
          <div class="name">
            <a fancybox data-type="ajax" :data-src="`/hotel_info/${itinerary.attributes.hotel_id}`" data-options="{touch:false,baseClass:null}" href="javascript:void(0);">
              {{ itinerary.attributes.name }}
            </a>
          </div>

          <div class="rating">
            <img v-for="i in itinerary.attributes.rating" :key="`star_${itinerary.id}_${i}`" :src="'/assets/shared/star.png' | cloudinary">
          </div>

          <div class="address">
            <a fancybox data-type="ajax" :data-src="`/hotel_map/${itinerary.attributes.hotel_id}`" data-options="{touch:false,baseClass:null}" href="javascript:void(0);">
              {{ itinerary.attributes.address }}<img :src="'/assets/shared/map_view.png' | cloudinary">
            </a>
          </div>
        </div>

        <div class="price-box-hotel">
          <div class="pax-price">
            <div class="price">{{ itinerary.attributes.price_pax }}</div>
            <div class="smallprice">{{ "per person" | locale }}</div>
          </div>

          <div class="total-price">
            <div class="price">{{ itinerary.attributes.price }}</div>
            <div class="smallprice">{{ "totalt" | locale }}</div>
          </div>
        </div>
        <div class="hotel_clear"></div>

        <div class="description">
            <div class="tripadvisor" v-if="itinerary.attributes.tripadvisor">
              {{ "TripAdvisor-betyg" | locale }}
              <div class="tripadvisor_image"><img :src="itinerary.attributes.tripadvisor.rating_image_url | cloudinary" /></div>
              <div class="tripadvisor_reviews">{{ "Baserat på" | locale }} <a :href="itinerary.attributes.tripadvisor.web_url" target="_blank">{{ "%{num} omdömen" | locale(itinerary.attributes.tripadvisor.num_reviews) }}</a></div>
            </div>

          {{ itinerary.attributes.short_description }}
          <a fancybox data-type="ajax" :data-src="`/hotel_info/${itinerary.attributes.hotel_id}`" data-options="{touch:false,baseClass:null}" href="javascript:void(0);">Läs mer</a>
        </div>

        <div class="rooms">
          <div class="room" v-for="rooms in itinerary.attributes.rooms" :key="`hr_${itinerary.attributes.hotel_id}_${rooms.room_index}`">
            <div class="headline">{{ "Rum %{num}" | locale(rooms.room_index) }} <div class="pax-text">({{ roomText(rooms.room_index) }})</div></div>
            <div class="options">
              <label class="table" v-for="(room, index) in rooms.room" :key="`hr_${itinerary.attributes.hotel_id}_${rooms.room_index}_${index + 1}_${room.uuid}`">
                <ul class="table_div">
                  <li class="radio">
                    <input type="radio" :name="`hr_${itinerary.attributes.hotel_id}_${rooms.room_index}`" :value="room.uuid"
                           :data-price="room.price_raw" @change="onRoomChange" :disabled="lockedRooms" />
                  </li>
                  <li class="li_1">{{ room.description }}</li>
                  <li class="li_2">{{ room.boardtype_text }}</li>
                  <li class="li_3">{{ room.price_diff }}</li>
                </ul>
              </label>
            </div>
          </div>
        </div>

        <div class="continue-button-hotel">
          <template v-if="selected">
            <div class="spinner" style="text-align: center;">
            <img :src="'/assets/shared/ajax-loader_white.gif' | cloudinary">
            </div>
          </template>
          <template v-else>
            <div class="y-button">
              <input type="submit" name="commit" :value="submitText" class="hotel">
            </div>
          </template>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
/* global gon */
import { translate, serializeForm, showException, showTicketsNotConfirmedAlert } from "app/helpers";

function findDefaultRoomIndex(rooms) {
  for (var roomIndex = 0; roomIndex < rooms.length; roomIndex++) {
    if (rooms[roomIndex].price_diff_raw === 0) {
      return roomIndex;
    }
  }
  return 0;
}

export default {
  props: {
    itinerary: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected: false,
      selectedPrice: null,
      imagePath: null
    };
  },
  computed: {
    currentPrice() {
      var price = this.selectedPrice || this.itinerary.attributes.price_raw;
      if (this.$store.state.alternativeMarkup) {
        price += this.$store.state.alternativeMarkup;
      }
      return this.$options.filters.currency(price);
    },
    month_price_url() {
      const raw_price = this.selectedPrice || this.itinerary.attributes.price_raw;
      return `/payment/annuity_description/${raw_price * 100}`;
    },
    locked() {
      if (this.selected) {
        return false;
      }
      return this.$store.state.lockItineraries;
    },
    lockedRooms() {
      return this.$store.state.lockItineraries;
    },
    submitText() {
      const isPackage = this.$store.state.search_parameters.type === "package";
      if (isPackage) {
        return `${translate("Välj flyg + hotell:")} ${this.currentPrice}`;
      } else {
        return `${translate("Välj hotell:")} ${this.currentPrice}`;
      }
    }
  },
  methods: {
    placesLeftText(num) {
      return (num === 1) ? translate("Bara en plats kvar!") :
                           translate("Bara %{num} platser kvar!", num);
    },
    onRoomChange(event) {
      this.priceChange();
    },
    setImage(url) {
      this.imagePath = url;
    },
    priceChange() {
      let newPrice = 0;

      $(`[data-uuid="${this.itinerary.id}"] .selected_hotel`).removeClass("selected_hotel");
      $(`[data-uuid="${this.itinerary.id}"] [type="radio"]:checked`).each((_, value) => {
        $(value).closest(".table").addClass("selected_hotel");
        newPrice += Number(value.getAttribute("data-price"));
      });

      this.selectedPrice = newPrice;
      return true;
    },
    roomText(index) {
      const room = this.$store.state.hotelRoomsPax[index - 1];
      if (room) {
        if (room.adults > 1) {
          return translate("%{num} vuxna", room.adults);
        } else {
          return translate("%{num} vuxen", room.adults);
        }
      } else {
        return translate("%{num} vuxna", 2);
      }
    },
    submit(event) {
      event.preventDefault();

      const target = $(event.target);
      let submitData = serializeForm(target);

      if (this.$store.state.alternativeBaggageData) {
        submitData.baggage = this.$store.state.alternativeBaggageData;
      }

      // Add loading to all itineraries
      this.selected = true;
      this.$store.state.lockItineraries = true;

      this.$http.post(`/search/check/${gon.search.id}`, submitData)
                .then((response) => {
                  if (response.body.available) {
                    window.location.href = response.body.url;
                  } else {
                    showTicketsNotConfirmedAlert();
                  }
                }, (response) => {
                  showException();
                });
    }
  },
  mounted() {
    $("[title]:not(.tooltipstered)", this.$refs.form).tooltipster({ contentAsHTML: true });
    $("[fancybox]", this.$refs.form).fancybox();

    this.$nextTick(() => {
      const itinScope = `[data-uuid="${this.itinerary.id}"]`;
      for (var i = 0; i < this.itinerary.attributes.rooms.length; i++) {
        var rooms = this.itinerary.attributes.rooms[i].room;
        var roomIndex = findDefaultRoomIndex(rooms);

        const name = `hr_${this.itinerary.attributes.hotel_id}_${i + 1}`;
        const uuid = rooms[roomIndex].uuid;
        const target = $(`${itinScope} [name="${name}"][value="${uuid}"]`);
        target.prop("checked", true);
        target.closest(".table").addClass("selected_hotel");
      }
    });
  }
};
</script>
