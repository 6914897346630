import { __ } from "../../../common/translation";
import { Tabs } from "../../tabs";

import { Autocomplete } from "./autocomplete";
import { Datepickers } from "./datepickers";

declare const gon: any;

export module State {

  export function hide_searchfield(): void {
    if ($("#search_box_id").attr("data-small")) {
      return;
    }

    let datepickers: number = 0;
    $(".datepicker").each((index: number, elem: Element): void => {
      if (Number($(elem).css("left")) > 0) {
        datepickers += 1;
      }
    });

    if ($(".search_box_overlay:visible").length === 0 && datepickers === 0) {
      $(".search-field").addClass("search-field-small");
      $("#search_box_id").addClass("search_box_small");
    }
  }

  export function show_searchfield(): void {
    $(".search-field").removeClass("search-field-small");
    $("#search_box_id").removeClass("search_box_small");
  }
  function update_search_airline(): void {
    $(".ui-selectmenu-text", $("#search_airline-button")).html(
      __("Flygbolag") + ": " + $(".ui-selectmenu-text", $("#search_airline-button")).html());
  }

  function update_search_cabin(): void {
    $(".ui-selectmenu-text", $("#search_cabin-button")).html(
      __("Biljettyp") + ": " + $(".ui-selectmenu-text", $("#search_cabin-button")).html());
  }

  function update_search_pax(): void {
    var adults = parseInt($("select.select_adults").val()) || 0;
    if (adults == 1) {
      $(".ui-selectmenu-text", $("#search_adults-button")).html(
        adults + " " + __("vuxen")
      )
    } else {
      $(".ui-selectmenu-text", $("#search_adults-button")).html(
        adults + " " + __("vuxna")
      )
    }

    $(".ui-selectmenu-text", $("#search_no_children-button")).html(
      $("select.select_no_children").val() + " " + __("barn")
    )

    $(".ui-selectmenu-text", $("#search_youths-button")).html(
      $("select.select_age_youths").val() + " " + __("ungdomar")
    )
  }

  function load_package(): void {
    $("#search_advanced_hotel_search").on("change", (eventObject: JQueryEventObject): void => {
      if ($(eventObject.target).prop("checked")) {
        $(".advanced_hotel_search").show();
      } else {
        $(".advanced_hotel_search").hide();
      }
    });

    $(".multi_datepicker").each((key: number, elem: Element) => {
      const target: JQuery<HTMLElement> = $(elem);
      target.datepicker({
          language: gon.locale,
          minDate: new Date(target.attr("data-mindate")),
          maxDate: new Date(target.attr("data-maxdate")),
          autoClose: true
        })
        .data("datepicker")
        .selectDate(new Date(target.attr("data-defaultDate")));
    });
  }

  function load_multicity(): void {
    $(".add_multi_leg").parent().off("click").on("click", add_multi_leg);
    $(".remove_multi_leg").parent().off("click").on("click", remove_multi_leg);
    $(".multi_datepicker").on("change", (eventObject: JQueryEventObject) => {
      const target: JQuery<HTMLElement> = $(eventObject.target);
      const id: number = Number(target.attr("data-number"));
      if (id < 10) {
        $(`#search_legs_${id}_date`).datepicker("option", "minDate", target.val());
      }
    });

    $(".airport_field_destination").on("autocompleteselect", (eventObject: JQueryEventObject, ui: any): void => {
      const id : number = Number($(eventObject.target).attr("data-number"));
      const value : string = $(`#search_legs_${id + 1}_origin_text`).val();
      if (value.length === 0) {
        $(`#search_legs_${id + 1}_origin_text`).val(ui.item.fullname);
        $(`#search_legs_${id + 1}_origin_id`).val(ui.item.id);
      }
    });

    $(".multi_datepicker").each((index: number, elem: Element): void => {
      const target: JQuery<HTMLElement> = $(elem);
      const elemMinDate: Date = new Date(target.attr("data-mindate"));
      const elemMaxDate: Date = new Date(target.attr("data-maxdate"));
      const defaultDate: Date = new Date(target.attr("data-defaultDate"));
      target.datepicker({
        language: gon.locale,
        minDate: elemMinDate,
        maxDate: elemMaxDate,
        autoClose: true,
        altFieldDateFormat: "yyyy-mm-dd",
        onShow(inst, animationCompleted): boolean {
          if (animationCompleted) {
            return true;
          }
          const id : number = Number(inst.$el.attr("id").split("_")[2]);
          if (id < 0) {
            return true;
          }
          const previous : JQuery<HTMLElement> = $(`#search_legs_${id - 1}_date`);
          if (previous.length === 0) {
            return true;
          }
          const date: Date = previous.datepicker().data("datepicker").selectedDates[0];
          return inst.update("minDate", date);
        },
        onSelect(formattedDate: string, date : Date , inst: JQuery<HTMLElement> ): void {
          const id: number = Number(inst.$el.attr("id").split("_")[2]);
          const noCount: number = $("#search_no_legs").val();
          if (!noCount || id >= noCount) {
            return;
          }

          for (let i: number = id + 1; i < noCount; i += 1) {
            const legId: JQuery<HTMLElement> = $(`#search_legs_${i}_date`);
            const legIdDate: Date = $(`#search_legs_${i}_date`)
                              .datepicker().data("datepicker").selectedDates[0];
            if (!legIdDate || legIdDate > date) {
              continue;
            }
            legId.datepicker().data("datepicker").selectDate(date);
          }
        }
      }).data("datepicker").selectDate(defaultDate);
    });
  }


  function add_multi_leg(): void {
    if (Number($("#search_no_legs").val()) >= 10) {
      return;
    }

    const newNumber: number = Number($("#search_no_legs").val());
    $("#search_no_legs").val(newNumber + 1);
    $("#multi_leg_" + newNumber).show();

    const lastDate: Date =
      $(`#search_legs_${newNumber - 1}_date`).datepicker().data("datepicker").selectedDates[0];

    const lastValue: JQuery<HTMLElement> = $(`#search_legs_${newNumber}_origin_text`);
    const lastValueId: JQuery<HTMLElement> = $(`#search_legs_${newNumber}_origin_id`);
    const newValue: string = $(`#search_legs_${newNumber - 1}_destination_text`).val();
    const newValueId : string = $(`#search_legs_${newNumber - 1}_destination_id`).val();
    if (lastValue.length !== 0) {
      lastValue.val(newValue);
      lastValueId.val(newValueId);
      $(`#search_legs_${newNumber}_destination_text`).val("");
      $(`#search_legs_${newNumber}_destination_id`).val("");
    }
    const newDate: Date = new Date(lastDate);
    newDate.setDate(lastDate.getDate() + 7);
    $(`#search_legs_${newNumber}_date`).datepicker().data("datepicker").selectDate(newDate);
    if (newNumber === 10) {
      $(".multi_add_ul").hide();
    }
  }

  function remove_multi_leg(e: JQueryEventObject): void  {
    const target: JQuery<HTMLElement> = $(e.currentTarget);
    if (Number($("#search_no_legs").val()) < 2) {
      return;
    }

    const removeId: number = Number(target.attr("data-number"));
    const highestId: number = Number($("#search_no_legs").val());

    if (removeId === highestId) {
      $(`#multi_leg_${removeId}`).hide();
      $("#search_no_legs").val(highestId);
      return;
    }

    let i: number;
    let end: number;
    let asc : boolean;
    for (i = removeId, end = highestId, asc = removeId <= end; asc ? i <= end : i >= end; asc ? i += 1 : i -= 1) {
      $(`#search_legs_${i}_origin_text`).val($(`#search_legs_${i + 1}_origin_text`).val());
      $(`#search_legs_${i}_origin_id`).val($(`#search_legs_${i + 1}_origin_id`).val());
      $(`#search_legs_${i}_destination_text`).val($(`#search_legs_${i + 1}_destination_text`).val());
      $(`#search_legs_${i}_destination_id`).val($(`#search_legs_${i + 1}_destination_id`).val());
    }
    $(`#multi_leg_${highestId - 1}`).hide();
    $("#search_no_legs").val(highestId - 1);
    return;
  }

  function load_field_actions(): void {
    $(".tooltip").tooltipster();
    $("select#search_airline").on("selectmenuchange", (): void => {
      update_search_airline();
    });

    $("select#search_cabin").on("selectmenuchange", (): void => {
      update_search_cabin();
    });

    $("select#search_rooms").on("selectmenuchange", (): void => {
      update_search_rooms();
    });
    // NOTE: This is a hack because of the autocompleta appends a space.
    $(".airport_field").blur((eventObject: JQueryEventObject) => {
      const target: JQuery<HTMLElement> = $(eventObject.target);
      if (target.val() === " ") {
        target.val("");
      }
    });

    $(".airport_field").click((elem) => {
      const target: JQuery<HTMLElement> = $(elem.target);
      if (target.val() !== " ") {
        target.select();
      }
    });

    $(".quick-link").click(quick_link_click);

    $(".right_left_arrow").on("click", (eventObject: JQueryEventObject): void => {
      const id: number = Number($(eventObject.currentTarget).attr("data-number"));
      const tmpTxt: string = $(`#search_legs_${id}_origin_text`).val();
      const tmpId: string = $(`#search_legs_${id}_origin_id`).val();
      $(`#search_legs_${id}_origin_text`).val($(`#search_legs_${id}_destination_text`).val());
      $(`#search_legs_${id}_origin_id`).val($(`#search_legs_${id}_destination_id`).val());
      $(`#search_legs_${id}_destination_text`).val(tmpTxt);
      $(`#search_legs_${id}_destination_id`).val(tmpId);
    });

    // Searchfield mouse over
    $(".search_box_small").on("mouseenter", function() {
      show_searchfield();
    });

    $(".search_box_small").on("mouseleave", function () {
      // HACK: Allow the selectmenu to be opened before we check.
      setTimeout(() => {
        if ($(".ui-selectmenu-open").length < 1) {
          hide_searchfield();
        }
      }, 100);
    });
  }

  function update_search_rooms(): void {
    const count: number = $("#search_rooms").val();
    for (let i: number = 1; i <= 4; i += 1) {
      if (i <= count) {
        $(`#ul_pax_${i}`).show();
        $(`#search_room_${i}_no_children`).each(update_pax_no_fields);
      } else {
        $(`#ul_pax_${i}`).hide();
        $(`#pax_no_header_${i}`).hide();
        $(`#pax_no_fields_${i}`).hide();
      }
    }
  }

  function update_pax_no_fields(index: number, elem: Element): void {
    const paxs: number = $(elem).val();
    const id: string = $(elem).attr("id");
    let ids: string = "";
    if (id.indexOf("_room_") !== -1) {
      ids = "_" + id.split("_")[2];
    }
    if (paxs > 0) {
      $(`#pax_no_header${ids}`).show();
      $(`#pax_no_fields${ids}`).show();
      for (let i: number = 1; i <= 8; i += 1) {
        if (i <= paxs) {
          $(`#pax_no${ids}_${i}`).show();
        } else {
          $(`#pax_no${ids}_${i}`).hide();
        }
      }
    } else {
      $(`#pax_no_header${ids}`).hide();
      $(`#pax_no_fields${ids}`).hide();
    }
  }

  function update_youth_pax_no_fields(index: number, elem: Element): void {
    const paxs: number = $(elem).val();
    const id: string = $(elem).attr("id");
    let ids: string = "";
    if (id.indexOf("_room_") !== -1) {
      ids = "_" + id.split("_")[2];
    }
    if (paxs > 0) {
      $(`#youth_pax_no_header${ids}`).show();
      $(`#youth_pax_no_fields${ids}`).show();
      $('#youth-tickets-info').show();
      for (let i: number = 1; i <= 9; i += 1) {
        if (i <= paxs) {
          $(`#youth_pax_no${ids}_${i}`).show();
        } else {
          $(`#youth_pax_no${ids}_${i}`).hide();
        }
      }
    } else {
      $(`#youth_pax_no_header${ids}`).hide();
      $(`#youth_pax_no_fields${ids}`).hide();
    }
  }

  function quick_link_click(): void {
    $("#search_legs_0_origin_text").val($(this).attr("data-text"));
    $("#search_legs_0_origin_id").val($(this).attr("data-id"));
    $("#search_legs_0_destination_text").focus();
  }

  export function load(): void {
    $("select.custom").selectmenu();
    Tabs.customCheckbox("search[only_direct_flights]");
    Tabs.customCheckbox("search[only_with_bags]");
    Tabs.customCheckbox("search[max_one_stop]");
    Tabs.customCheckbox("search[only_private_fares]");
    Tabs.customCheckbox("search[only_public_fares]");
    Tabs.customCheckbox("search[no_ndc]");
    Tabs.customCheckbox("search[only_rebookable]");
    Tabs.customCheckbox("search[advanced_hotel_search]");
    update_search_airline();
    update_search_cabin();

    $("select.select_adults").on("selectmenuchange", (eventObject: JQueryEventObject, ui: any): void => {
      if (parseInt($("select.select_adults").val()) > 0) {
        $("select.select_age_youths").val('0');
        $("#youth_pax_no_header").hide();
        $("#youth_pax_no_fields").hide();
      } else {
        $("select.select_adults").val('1')
      }
      $('#youth-tickets-info').hide();
      update_search_pax();
    });

    $("select.select_no_children").each(update_pax_no_fields);
    $("select.select_no_children").on("selectmenuchange", (eventObject: JQueryEventObject, ui: any): void => {
      if (parseInt($("select.select_no_children").val()) > 0) {
        $("select.select_age_youths").val('0');
        $("#youth_pax_no_header").hide();
        $("#youth_pax_no_fields").hide();
        var adults = parseInt($("select.select_adults").val()) || 0;
        if (adults < 1) {
          $("select.select_adults").val('1');
        }
        $('#youth-tickets-info').hide();
        update_search_pax();
      }
      update_pax_no_fields(ui.item.index, eventObject.target);
    });

    $("select.select_age_youths").each(update_youth_pax_no_fields);
    $("select.select_age_youths").on("selectmenuchange", (eventObject: JQueryEventObject, ui: any): void => {
      if (parseInt($("select.select_age_youths").val()) > 0) {
        $("select.select_adults").val('0');
        $("select.select_no_children").val('0');
        $("#pax_no_header").hide();
        $("#pax_no_fields").hide();
        $('#youth-tickets-info').show();
      } else {
        $("select.select_adults").val('1');
        $('#youth-tickets-info').hide();
      }
      update_search_pax();
      update_youth_pax_no_fields(ui.item.index, eventObject.target);
    });

    Autocomplete.load_autocomplete_fields();
    load_field_actions();

    const elem: Element = $("input[type=radio][name=\"search[type]\"]:checked")[0];
    if (elem && $(elem).val() === "multi") {
      load_multicity();
    } else if (elem && $(elem).val() === "package") {
      Datepickers.load_datepickers_inline();
      load_package();
    } else {
      Datepickers.load_datepickers_inline();
    }
  }
}
